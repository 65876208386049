import { Select } from 'antd/es'
import type { FC, ReactNode } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { flushSync } from 'react-dom'

import { useGetStationsQuery } from '@redux/features/search/search.api'
import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'

import { searchFormId } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import { STATIONS_DROPDOWN_CLASSNAME } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/stationsFields/StationsFields'
import StationOption from '@components/mainPage/mainBlock/searchTrains/search/searchForm/stationsFields/stationSelect/stationOption/StationOption'
import useMediaQuery from '@hooks/mediaQueries/useMediaQuery'
import useToggle from '@hooks/useToggle/useToggle'
import MapMarkerIcon from '@iconscout/unicons/svg/line/map-marker.svg'
import CloseIcon from '@iconscout/unicons/svg/line/times.svg'
import { mediaQueries } from '@themes/mediaQueries'
import { focusOnNextField } from '@utils/forms/fields'

import { DirectionLabel, GlobalStyles, SelectStyled, StationSelectWrapper } from './styles'

const { Option } = Select

type Props = {
  departureStation?: ReactNode
  directionLabel: string
  disableClear?: boolean
  id: string
  isFocused: boolean
  onBlur: () => void
  onFocus: () => void
  otherStationId?: string
  placeholder: string
  setValue: (value?: SearchStationsOptionType) => void
  value?: SearchStationsOptionType
}

export const StationSelectField: FC<Props> = ({
  directionLabel,
  disableClear,
  id,
  isFocused,
  onBlur,
  onFocus,
  otherStationId,
  placeholder,
  setValue,
  value,
}) => {
  const isMobile = useMediaQuery(mediaQueries.mobile)
  const [searchValue, setSearchValue] = useState<string>(value?.label || '')
  const { on: isOpened, toggleOff: close, toggleOn: open } = useToggle()
  const { on: waitingResponse, toggleOff: waitingResponseOff, toggleOn: waitingResponseOn } = useToggle(true)
  const { currentData, data: options = value ? [value] : [] } = useGetStationsQuery(
    { name: searchValue.trim(), selectedStationId: otherStationId },
    { skip: !isFocused || (!searchValue && !otherStationId) }
  )
  const isSelectOpened = !waitingResponse && (otherStationId ? isOpened : isOpened && !!searchValue)

  useEffect(() => {
    setSearchValue(value?.label || '')
  }, [value])

  useEffect(() => {
    waitingResponse && currentData && waitingResponseOff()
  }, [currentData, waitingResponse, waitingResponseOff])

  const onSearch = useCallback(
    (name: string) => {
      if (!name && !otherStationId) waitingResponseOn()
      isFocused && setSearchValue(name)
    },
    [isFocused, otherStationId, waitingResponseOn]
  )

  const onClear = useCallback(() => {
    setValue(undefined)
    setSearchValue('')
  }, [setValue])

  const onBlurHandle = useCallback(() => {
    close()
    !searchValue ? onClear() : setSearchValue(value?.label || '')
  }, [close, searchValue, onClear, value?.label])

  const onFocusHandle = useCallback(() => {
    open()
    const newValue = value ? value.label : ''
    setSearchValue(newValue)
    flushSync(() => {
      onFocus()
    })
  }, [id, onFocus, open, value])

  const onSelect = useCallback(
    (newValue: string) => {
      const newOption = options.find(option => option.value === newValue)
      if (newOption) {
        setValue(newOption)
        setSearchValue(newOption.label)
        setTimeout(() => {
          close()
          isMobile ? onBlur() : focusOnNextField({ formId: searchFormId })
        }, 0)
      }
    },
    [options, close, setValue, isMobile, onBlur]
  )

  const withValue = useMemo(() => !!(searchValue || value), [searchValue, value])

  const optionsArray = useMemo(() => {
    if (options?.length) {
      return value && value.label.toLowerCase().includes(searchValue.toLowerCase())
        ? [value, ...options.filter(option => option.value !== value.value)]
        : options
    }
    return []
  }, [options, searchValue, value, waitingResponse])

  return (
    <StationSelectWrapper $withValue={withValue}>
      <DirectionLabel>{directionLabel}</DirectionLabel>
      <SelectStyled
        labelRender={props => {
          return <StationOption {...props} />
        }}
        allowClear={disableClear ? false : { clearIcon: <CloseIcon /> }}
        aria-label={placeholder}
        autoClearSearchValue={false}
        className={searchValue.length || value ? 'withValue' : undefined}
        dropdownAlign={{ htmlRegion: 'visible', overflow: { adjustY: true } }}
        filterOption={() => true}
        id={id}
        listHeight={isMobile ? 250 : 300}
        listItemHeight={56}
        onBlur={onBlurHandle}
        onClear={onClear}
        onFocus={onFocusHandle}
        onSearch={onSearch}
        onSelect={onSelect}
        open={isSelectOpened}
        placeholder={placeholder}
        popupClassName={STATIONS_DROPDOWN_CLASSNAME}
        popupMatchSelectWidth={true}
        searchValue={searchValue}
        showSearch
        suffixIcon={<MapMarkerIcon />}
        value={value?.value}
        variant="borderless"
      >
        {optionsArray.map(option => (
          <Option key={option.value} value={option.value}>
            <StationOption {...option} />
          </Option>
        ))}
      </SelectStyled>
      <GlobalStyles />
    </StationSelectWrapper>
  )
}
