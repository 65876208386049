import styled from 'styled-components'

import { rootDropdownClassName } from '@constants/dropdown/dropdown'

import { DivItemWrapper, LinkItemWrapper } from './components/MenuItem/styles'

export const NavSelectorWrapper = styled.nav`
  margin-inline-start: auto;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
`

export const DropdownContainer = styled.div`
  height: 100%;

  .${rootDropdownClassName}.ant-dropdown {
    &.with-submenu .ant-dropdown-menu {
      border-right: 1px solid ${p => p.theme.colors.fontSecondaryLight};
      border-radius: 16px 0 0 16px;
    }

    .ant-dropdown-menu {
      border-radius: ${p => p.theme.borderRadius.default};
      transition: ${p => p.theme.transitions.default};

      .ant-dropdown-menu-submenu {
        ${LinkItemWrapper} > *, ${DivItemWrapper} > * {
          color: ${p => p.theme.colors.fontSecondary};

          &::after {
            content: '';
            position: absolute;
            height: 4px;
            border-radius: 2px;
            width: 100%;
            background: ${p => p.theme.colors.primary};
            left: 0;
            bottom: -10px;
            opacity: 0;
            transition: ${p => p.theme.transitions.default};
          }
        }

        &.ant-dropdown-menu-submenu-open {
          ${LinkItemWrapper} > *, ${DivItemWrapper} > * {
            color: ${p => p.theme.colors.primary};

            &::after {
              opacity: 1;
            }
          }
        }

        .ant-dropdown-menu-submenu-title {
          padding: 0;

          .ant-dropdown-menu-submenu-expand-icon {
            display: none;
          }
        }
      }
    }
  }

  .${rootDropdownClassName}.ant-dropdown, .navselectors-submenu.ant-dropdown-menu-submenu {
    .ant-dropdown-menu {
      background: ${p => p.theme.colors.backgroundMain};
      padding: 18px 4px;
      box-shadow: ${p => p.theme.shadows.modal};
    }
  }

  .${rootDropdownClassName}.ant-dropdown, .navselectors-submenu.ant-dropdown-menu-submenu {
    .ant-dropdown-menu {
      max-height: calc(100vh - 120px);
      overflow-y: auto;
    }
  }

  .navselectors-submenu.ant-dropdown-menu-submenu {
    .ant-dropdown-menu-item {
      padding: 0;
    }

    &.ant-dropdown-menu-submenu-placement-rightTop {
      border-radius: 0 ${p => (p.theme.borderRadius.default + ' ').repeat(3)};
    }

    &.ant-dropdown-menu-submenu-placement-leftTop {
      border-radius: ${p => (p.theme.borderRadius.default + ' ').repeat(3)}0;
    }

    .ant-dropdown-menu {
      border-radius: inherit;
      box-shadow: 20px 0 50px 0 rgba(0, 0, 0, 0.1);
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`
