import Link from 'next/link'
import styled, { css } from 'styled-components'

import { Title6 } from '@typography/Title'

const commonStyles = css`
  display: block;
  padding: 14px 36px;

  ${Title6} {
    transition: ${p => p.theme.transitions.default};
    width: fit-content;
    position: relative;
  }
`

export const LinkItemWrapper = styled(Link)`
  ${commonStyles};
`

export const DivItemWrapper = styled.div`
  ${commonStyles};
`
