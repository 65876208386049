import type { LinkProps } from 'next/link'
import type { FC, ReactElement } from 'react'

import { getRoutes } from '@constants/routes/routes'
import useVersionPage from '@hooks/useVersionPage'

import { GoHomeHocStyled } from './styles'

export type GoHomeHocProps = {
  children: ReactElement
  to?: LinkProps['href']
} & Omit<LinkProps, 'href'>

export const GoHomeHoc: FC<GoHomeHocProps> = ({ children, to, ...props }) => {
  const ver = useVersionPage()
  return (
    <GoHomeHocStyled href={to || getRoutes.rootPath(ver)} {...props} aria-label="go home link">
      {children}
    </GoHomeHocStyled>
  )
}
